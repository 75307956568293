import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import * as DK from "design-kit"

import sorry from "../../views/ProtectionGet/assets/hero/sorry/tablet-sorry.png"
import sorryWebp from "../../views/ProtectionGet/assets/hero/sorry/tablet-sorry.webp"
import sorryDesktop from "../../views/ProtectionGet/assets/hero/sorry/desktop-sorry.png"
import sorryDesktopWebp from "../../views/ProtectionGet/assets/hero/sorry/desktop-sorry.webp"

const HeroImage: React.FC = () => {
  const isDesktop = DK.useMediaQuery(DK.breakpoints.desktop.query)
  const hasMounted = DK.useHasMounted()
  return isDesktop ? (
    <picture
      key={`${hasMounted}`}
      css={css`
        position: absolute;
        z-index: 0;
        top: 0;
        right: -160px;
      `}
    >
      <source srcSet={sorryDesktopWebp} type="image/webp" />
      <img src={sorryDesktop} alt="" />
    </picture>
  ) : (
    <picture
      css={css`
        position: absolute;
        z-index: 0;
        transform: scale(0.5);
        top: -180px;
        left: 0px;
        right: 0px;
        margin: auto;
        text-align: center;
        ${DK.breakpoints.tablet`
          transform: revert;
          top: 0;
          left: revert;
          right: -20px;
        `}
      `}
    >
      <source srcSet={sorryWebp} type="image/webp" />
      <img src={sorry} alt="" />
    </picture>
  )
}

const HeroText = styled(DK.Statement1)`
  position: relative;
  z-index: 1;
  margin-right: 0px;
  ${DK.breakpoints.tablet`
    margin-right: 190px;
  `}
  ${DK.breakpoints.desktop`
    margin-right: 290px;
  `}
`

export const Hero: React.FC = () => (
  <DK.GridRow>
    <div
      css={css`
        position: relative;
        margin-top: calc(${DK.vertical.xl} + ${DK.vertical.xl});
        margin-bottom: ${DK.vertical.m};

        ${DK.breakpoints.desktop`
          margin-top: calc(${DK.vertical.xxl} + ${DK.vertical.xxl});
          margin-bottom: ${DK.vertical.l};
        `}

        ${DK.column({
          widthMobile: 4,
          offsetMobile: 0,
          widthTablet: 6,
          offsetTablet: 0,
          widthDesktop: 10,
          offsetDesktop: 1,
        })}
      `}
    >
      <HeroText>
        If things go wrong, protection can help keep you in your home
      </HeroText>

      <HeroImage />
    </div>
  </DK.GridRow>
)

export const Intro: React.FC = () => (
  <DK.GridRow>
    <section
      css={css`
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: ${DK.vertical.s};

        ${DK.column({
          widthMobile: 4,
          offsetMobile: 0,
          widthTablet: 4,
          offsetTablet: 0,
          widthDesktop: 6,
          offsetDesktop: 2,
        })}
      `}
    >
      <DK.Headline>
        If you die or become too ill to work, insurance helps protect you and
        your loved ones.
      </DK.Headline>

      <DK.Headline>
        Whether you’re buying or remortgaging your home, or your circumstances
        have changed, it’s really important that you are aware of the options
        available and have the right cover in place.
      </DK.Headline>

      <DK.Headline>
        Our Protection Team are here to give you expert advice on your options -
        for <strong>free</strong>. We make the process simple. We provide you
        with personalised advice to help you choose the right cover for you and
        your loved ones. We work for <strong>you</strong>, not the insurance
        providers.
      </DK.Headline>
    </section>
  </DK.GridRow>
)
