import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { withCalendly } from "../types/calendly"

import * as DK from "design-kit"

import { WrappedBase } from "../shared-components/Shared/WrappedBase"
import Footer from "../shared-components/Footer"
import Navbar from "../shared-components/Navbar"

import heroMobile from "../assets/backgrounds/hero-sky-b/mobile_BG.jpg"
import heroTablet from "../assets/backgrounds/hero-sky-b/tablet_BG.jpg"
import heroDesktop from "../assets/backgrounds/hero-sky-b/desktop_BG.jpg"
import heroMobile2x from "../assets/backgrounds/hero-sky-b/mobile_BG_retina.jpg"
import heroTablet2x from "../assets/backgrounds/hero-sky-b/tablet_BG_retina.jpg"
import heroDesktop2x from "../assets/backgrounds/hero-sky-b/desktop_BG_retina.jpg"

import { Hero, Intro } from "../views/ProtectionGet/Hero"
import { StepList } from "../views/ProtectionGet/StepList"

import config from "../../config.json"

const GetStarted: React.FC = () => (
  <DK.GridRow>
    <section
      css={css`
        ${DK.column({
          widthMobile: 2,
          offsetMobile: 1,
          widthTablet: 2,
          offsetTablet: 2,
          widthDesktop: 4,
          offsetDesktop: 4,
        })}
      `}
    >
      <DK.PrimaryCTA
        text="Get started"
        onClick={() => {
          withCalendly(Calendly => {
            Calendly.initPopupWidget({
              url: "https://calendly.com/d/cmbn-dzn-vq4/chat-with-a-protection-expert?primary_color=006be6?hide_landing_page_details=1",
            })
          })
        }}
      />
    </section>
  </DK.GridRow>
)

const LearnMore: React.FC = () => (
  <div
    css={css`
      text-align: center;
    `}
  >
    <DK.BodyBold>
      Head to our <a href="/protection-importance">learn pages</a> to read more
      about why protection is so important.
    </DK.BodyBold>
  </div>
)

const Layout = styled.div`
  margin: 0 auto;
  max-width: ${DK.maxWidth}px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: ${DK.vertical.l};
`

const FALLBACK_GRADIENT = `linear-gradient(
  190deg,
  ${DK.colours.gradientBase.mistyViolet} 75%,
  ${DK.colours.gradientBase.seafoam}
)`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  color: ${DK.colours.offBlack};
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  ${DK.mixins.gradientBackground({
    fallbackColor: DK.colours.gradientBase.mistyViolet,
    mobileFallbackGradient: FALLBACK_GRADIENT,
    mobileBackgroundImage: heroMobile,
    mobileBackgroundImageRetina: heroMobile2x,
    tabletFallbackGradient: FALLBACK_GRADIENT,
    tabletBackgroundImage: heroTablet,
    tabletBackgroundImageRetina: heroTablet2x,
    desktopFallbackGradient: FALLBACK_GRADIENT,
    desktopBackgroundImage: heroDesktop,
    desktopBackgroundImageRetina: heroDesktop2x,
  })}
  padding-bottom: ${DK.vertical.l};
`

const PageContent: React.FC = () => (
  <React.Fragment>
    <Navbar theme="transparent-dark" />

    <Main id="main-content">
      <Layout>
        <section>
          <Hero />
          <Intro />
        </section>

        <StepList />
        <GetStarted />
        <LearnMore />
      </Layout>
    </Main>

    <Footer />
  </React.Fragment>
)

const ProtectionGetPage: React.FC = () => (
  <WrappedBase
    metaTitle="Get protected | Habito"
    metaDescription="Our Protection Team are here to give you expert advice on your options - for free. We provide you with personalised advice to help you choose the right cover for you and your loved ones. We work for you, not the insurance providers."
    canonicalUrl="https://www.habito.com/protection-get"
    noIndex={false}
    pageName="protection-get"
    intercom={true}
    config={config}
    head={[
      <link
        rel="preload"
        as="style"
        // TS thinks `onload` isn't a valid prop since it doesn't realise this
        // is going to be stringified into HTML
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onload="this.onload=null;this.rel='stylesheet';"
        href="//assets.calendly.com/assets/external/widget.css"
      />,
      <script async src="//assets.calendly.com/assets/external/widget.js" />,
    ]}
  >
    <PageContent />
  </WrappedBase>
)

export default ProtectionGetPage
