import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import * as DK from "design-kit"

const StepStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${DK.vertical.xs};
  ${DK.breakpoints.tablet`
    gap: ${DK.vertical.l};
  `}
  ${DK.column({
    widthMobile: 4,
    offsetMobile: 0,
    widthTablet: 6,
    offsetTablet: 0,
    widthDesktop: 8,
    offsetDesktop: 2,
  })}
`

const StepRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${DK.vertical.s};
`

const StepCard: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <DK.Card
    element="div"
    borderRadius="m"
    kind="semi-transparent"
    css={css`
      display: flex;
      flex-direction: row;
      gap: ${DK.vertical.xs} ${DK.horizontal.m};
      align-items: center;
      flex-wrap: wrap;
      align-content: flex-start;
    `}
  >
    {children}
  </DK.Card>
)

const StepCounter: React.FC<{ alt: string; children?: React.ReactNode }> = ({
  alt,
  children,
}) => (
  <span
    role="img"
    aria-label={alt}
    css={css`
      ${DK.typographyStyles.statement1}
    `}
  >
    {children}
  </span>
)

const StepTitle = styled(DK.Headline)`
  ${DK.typographyStyles.headline1}
  flex: 3;
`

const StepBody = styled(DK.Body)`
  ${DK.breakpoints.tablet`
    flex: 3;
  `}
  ${DK.breakpoints.desktop`
    flex: 2;
  `}
`

const SectionTitle = styled.h2`
  ${DK.typographyStyles.headline2}
  margin-bottom: ${DK.vertical.s};
  text-align: center;
  padding: 0 ${DK.horizontal.m};
`

const StepSpace: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const isTablet = DK.useMediaQuery(DK.breakpoints.tablet.query)
  const hasMounted = DK.useHasMounted()
  return isTablet ? (
    <div
      css={css`
        width: 150px;
        ${DK.breakpoints.desktop`
          width: 200px;
        `}
      `}
      key={`${hasMounted}`}
    >
      {children}
    </div>
  ) : null
}

const DecideOnYourCoverStep: React.FC = () => (
  <StepCard>
    <StepCounter alt="1">①</StepCounter>
    <StepTitle>Decide on your cover</StepTitle>
    <StepBody>
      Talk to one of our protection experts to find out what cover you need.
    </StepBody>
  </StepCard>
)

const GetYourQuotesStep: React.FC = () => (
  <StepCard>
    <StepCounter alt="2">②</StepCounter>
    <StepTitle>Get your quotes</StepTitle>
    <StepBody>
      We’ll search a large panel of providers to identify the right cover for
      you, and recommend what suits you best.
    </StepBody>
  </StepCard>
)

const GetProtectedStep: React.FC = () => (
  <StepCard>
    <StepCounter alt="3">③</StepCounter>
    <StepTitle>Get protected</StepTitle>
    <StepBody>
      We’ll take care of the paperwork and start your cover when you need it.
    </StepBody>
  </StepCard>
)

export const StepList: React.FC = () => (
  <section
    css={css`
      display: flex;
      flex-direction: column;
      gap: ${DK.vertical.s};
    `}
  >
    <SectionTitle>Three simple steps to the right cover</SectionTitle>
    <DK.GridRow>
      <StepStack role="list">
        <StepRow>
          <DecideOnYourCoverStep />
          <StepSpace />
        </StepRow>
        <StepRow>
          <StepSpace />
          <GetYourQuotesStep />
        </StepRow>
        <StepRow>
          <GetProtectedStep />
          <StepSpace />
        </StepRow>
      </StepStack>
    </DK.GridRow>
  </section>
)
